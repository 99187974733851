<template>
  <div :class="['triple-banner', { 'single-secondary': secondaryBanners?.length === 1 }]">
    <Banner v-if="mainBanner" v-bind="mainBanner" :is-primary-banner="true" />
    <Banner
      v-for="(banner, i) in secondaryBanners"
      :key="i + 'secBanner'"
      v-bind="banner"
      :is-secondary-banner="true"
      :class="[
        isVideo(banner) ? 'desk-only-flex' : 'desk-only',
        { 'single-secondary': secondaryBanners?.length === 1 },
      ]"
    />
    <WrapperSwiper :swiper-options="swiperOptions" class="tab-mob-only">
      <template v-for="(banner, idx) in secondaryBanners" :key="idx + 'secBanner-mob'">
        <swiper-slide>
          <Banner v-bind="banner" :is-secondary-banner="true" />
        </swiper-slide>
      </template>
    </WrapperSwiper>
  </div>
</template>

<script setup lang="ts">
import { SwiperOptions } from 'swiper/types';

// const tripleBannerPagination = ref<HTMLDivElement | null>(null);
const swiperOptions: SwiperOptions = {
  loop: false,
  a11y: {
    enabled: true,
  },
  injectStyles: [
    `
      .swiper {
        padding-bottom: 2.0625rem;
      }
    `,
  ],
  pagination: {
    type: 'bullets',
    clickable: true,
  },
};

const props = defineProps({
  /* eslint-disable vue/prop-name-casing */
  main_bannerConnection: {
    type: Object as () => any,
    required: true,
  },
  secondary_bannersConnection: {
    type: Object as () => any,
    required: true,
  },
});

const mainBanner = computed<any>(() => props.main_bannerConnection?.edges?.[0]?.node);
const secondaryBanners = computed<any[]>(() => {
  return (props.secondary_bannersConnection?.edges as any[])?.map((item: any) => item?.node).splice(0, 2);
});

function isVideo(banner: any): boolean {
  return banner?.background?.[0]?.type === 'VIDEO';
}
</script>

<style lang="scss">
.triple-banner swiper-container {
  margin-top: 1rem;
  padding-bottom: #{local-functions.rem-calc(33px)};
}
</style>

<style lang="scss" scoped>
// mobile swipe container and desktop secondary banner style
.triple-banner swiper-container :deep(.banner),
.triple-banner :deep(.banner):nth-child(2),
.triple-banner :deep(.banner):nth-child(3) {
  min-height: calc(#{local-functions.rem-calc(240)} - 30px);
  padding: 3rem;
  .content {
    h1,
    h2,
    h3,
    .h1,
    .h2,
    .h3 {
      @include local-mixins.h4;
    }
  }
  &.video {
    padding: 0;
    width: 100%;
    height: unset;

    video {
      width: 100%;
      height: unset;
    }
    .video-container {
      height: 100%;
    }
    .container {
      padding: 3rem;
    }
    @include local-mixins.tablet_and_mobile {
      .container {
        padding: 1.5rem;
      }
    }
  }
  @include local-mixins.tablet_and_mobile {
    max-height: #{local-functions.rem-calc(266)};
    padding: 1.5rem;
  }
}

.triple-banner {
  $spacing: 2rem;
  $half-spacing: 1rem;
  @include local-mixins.desktop {
    display: grid;
    padding: $spacing 0;
    grid-template-areas:
      'main banner-2'
      'main banner-3';
    grid-template-columns: calc(57% - $half-spacing) calc(43% - $half-spacing);
    grid-column-gap: $spacing;
    :deep(.banner) {
      border-radius: 4px;
      &:first-child {
        grid-area: main;
        .container {
          width: 75%;
        }
      }
      &:nth-child(2) {
        grid-area: banner-2;
        margin-bottom: $half-spacing;
      }
      &:nth-child(3) {
        grid-area: banner-3;
        margin-top: $half-spacing;
      }
      &:nth-child(2),
      &:nth-child(3) {
        .btn {
          padding: 1rem 1.5rem;
          height: 40px;
        }
        .container {
          width: 100%;
        }
      }
      &.video,
      .video-container::before,
      video {
        border-radius: 4px;
      }
    }
    &.single-secondary {
      grid-template-columns: calc(66% - $half-spacing) calc(34% - $half-spacing);
      grid-template-areas:
        'main banner-2'
        'main banner-2';
      :deep(.banner) {
        &:nth-child(2) {
          margin-bottom: 0;
        }
        &:nth-child(3) {
          margin-top: 0;
        }
      }
    }
  }
  @include local-mixins.xxl {
    padding: $spacing;
  }
}
</style>
